import { render, staticRenderFns } from "./Post.vue?vue&type=template&id=738d3ae8&scoped=true&"
import script from "./Post.vue?vue&type=script&lang=ts&"
export * from "./Post.vue?vue&type=script&lang=ts&"
import style0 from "./Post.vue?vue&type=style&index=0&id=738d3ae8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "738d3ae8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSparkline } from 'vuetify/lib/components/VSparkline';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VSheet,VSparkline,VTab,VTabItem,VTabs,VTabsItems,VToolbar})
